


























































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore, MetaStore } from "@/store/modules";
import { forgotPassword } from "@/store/models/jwt";
import { MetaInfo } from "vue-meta";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {
    Input
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("forgotPassword")}`,
      meta: [
        { name: "keyword", content: "Reset Password" },
        {
          name: "description",
          content: "Reset Password",
          vmid: "general"
        }
      ]
    };
  }
})
export default class Forgotpassword extends Vue {
  dialogPasswordChangeRequestFormVisible = false;
  payload: forgotPassword = {
    email: ""
  };

  passwordChangeMessage: string = "";
  passwordChangeTitle: string = "";
  dialogPasswordChangeFooter: boolean = true;

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  async submit(e: any) {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let translateMessage = await JWTStore.forgotPassword(this.payload);
      this.passwordChangeMessage = this.$t(translateMessage).toString();

      console.log(translateMessage);
      if (translateMessage == "passwordChangeNoUserFoundMessage") {
        this.passwordChangeTitle = this.$t("passwordChangeNoUserFoundTitle").toString();
        this.dialogPasswordChangeFooter = false;
      } else {
        this.passwordChangeTitle = this.$t("passwordChangeRequested").toString();
        this.dialogPasswordChangeFooter = true;
      }
      this.dialogPasswordChangeRequestFormVisible = true;
    }
  }

  login() {
    this.$router.push({ name: "Home" }).catch(error => {});
  }

  created() {
    this.passwordChangeMessage = this.$t("passwordChangeRequestMessage").toString();
    this.passwordChangeTitle = this.$t("passwordChangeRequested").toString();
  }
}
