















































































































































































































































































































































































































































import { Component } from "vue-property-decorator";
import { Vue as Vue_new } from "vue-property-decorator";
import Vue from "vue";
import { UserStore, JWTStore, MetaStore } from "@/store/modules";
import { changeUsername, changePassword } from "@/store/models/jwt";
import router from "@/router";
import axios from "axios";
import { eventHub } from "@/utils/eventHub";
import { User } from "@/store/models/user";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDateTime } from "@/store/modules/dateConverter";
import UserDetailDocument from "@/views/Admin/Components/UserDetailDocument.vue";

@Component({
  components: {
    UserDetailDocument
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("profile")}`,
      meta: [
        { name: "keyword", content: "Sqcc supply list" },
        {
          name: "description",
          content: "List of Supplies of seeds",
          vmid: "supplyList"
        }
      ]
    };
  }
})
export default class Profile extends Vue_new {
  username: string = "";
  companyId: number = 0;
  name: string = "";
  vm = new Vue();
  dialogChangePasswordFormVisible = false;
  passwordPayload: changePassword = {
    current_password: "",
    new_password: "",
    re_new_password: ""
  };
  fileList: File[] = [];
  file: File = {} as File;
  companyUsers = [
    "supplier",
    "consumer",
    "source_seed_producer",
    "improved_seed_producer",
    "improved_seed_distributor",
    "improved_seed_consumer"
  ];
  doadViewer = ["DLAO", "DOADViewer"];

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  nepaliDateTime(date: string) {
    return nepaliDateTime(date, this.$i18n.locale);
  }

  roleChecker(roles: String[]) {
    for (let role of roles) {
      if (this.user.role === role) {
        return true;
      }
    }
    return false;
  }

  normal_case(value: string) {
    if (value) {
      let splittedValue = value.split("_");
      let translatedValue = [];
      for (let value of splittedValue) {
        translatedValue.push(this.$t(value).toString());
      }
      value = translatedValue.join(" ");
    }
    return value;
  }

  async submitFile() {
    let formData = new FormData();
    formData.append("file", this.file);
    formData.append("username", this.username);

    await UserStore.changeProfilePicture(formData);
    this.handleRemove();
  }
  handleFileUpload(event: any) {
    this.fileList = [event];
    this.file = event.raw;
  }

  handleRemove() {
    this.fileList = [];
    this.file = {} as File;
  }

  async created() {
    await UserStore.getUser();
    this.username = UserStore.User.username;
    this.name = this.user.name;
    this.companyId = this.user.id;
    console.log(this.user);
  }

  get user() {
    let user = UserStore.User;
    if (user.oauth_provider == "facebook") {
      try {
        let anchor = document.createElement("a");
        anchor.href = user.picture;
        let queryStrings = anchor.search.substring(1);
        let params = queryStrings.split("&");
        let oauthId = params[0].split("=")[1];
        if (oauthId) {
          user.picture =
            "https://graph.facebook.com/" + oauthId + "/picture?type=large&height=500&width=500";
        }
      } catch {}
    } else if (user.oauth_provider == "google") {
      try {
        let params = user.picture.split("=s");
        let picture_url = params[0] + "=s500-c";
        if (picture_url) {
          user.picture = picture_url;
        }
      } catch {}
    }
    return user;
  }
  //
  // back() {
  //   router.push(commonStore.beforeProfile);
  // }
  //

  changePasswordDialog() {
    //   this.$validator.reset();
    //   this.errors.clear();
    this.passwordPayload = {
      current_password: "",
      new_password: "",
      re_new_password: ""
    } as changePassword;
    this.dialogChangePasswordFormVisible = true;
  }
  async submitPassword(e: any) {
    // let isValid = await this.$validator.validateAll();
    // if (isValid) {
    await JWTStore.changePassword(this.passwordPayload);
    this.dialogChangePasswordFormVisible = false;
    this.vm.$notify.success("Password Changed Successfully");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");
    // clearJWT();
    eventHub.$emit("reloader");
    JWTStore.logOut();
    this.$router.push({ name: "ChangeSuccess" }).catch(error => {});
    //   }
  }
}
